import {
  Block,
  Button,
  Card,
  Container,
  Flex,
  Text,
} from '@actovos-consulting-group/ui-core';
import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

export const query = graphql`
  {
    allFaq {
      nodes {
        question
        answer
        id
      }
    }
  }
`;

const FAQPage = ({
  data: {
    allFaq: { nodes },
  },
}) => {
  return (
    <Layout>
      <SEO
        title="Frequently Asked Questions"
        description="RoastorToast is a community where our team of experienced, and successful, entrepreneurs will provide you and your team valuable, actionable feedback to help you make the best product you can."
      />
      <Container>
        <Flex flexDirection={{ _: 'column-reverse', xs: 'row' }}>
          <Block flex={1} mr={{ _: 0, xs: 50 }}>
            <Text as="h2">Frequently Asked Questions</Text>
            {nodes.map(({ question, answer, id }) => (
              <Block mb={50} key={id}>
                <Text fontSize={20} fontWeight="bold">
                  {question}
                </Text>
                <Text dangerouslySetInnerHTML={{ __html: answer }} />
              </Block>
            ))}
          </Block>
          <Block mt={20} width={{ _: '100%', xs: 300 }}>
            <Card>
              <Text color="textColor" fontSize={30} fontWeight="bold">
                Got a question?
              </Text>
              <Text mt={20} mb={40}>
                Can’t find the answers you’re looking for in our FAQ? Send us
                your questions and we’ll do our best to answer them!
              </Text>
              <Button href="mailto:contact@roastortoast.io">
                Submit Question
              </Button>
            </Card>
          </Block>
        </Flex>
      </Container>
    </Layout>
  );
};

export default FAQPage;
